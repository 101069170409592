<template>
  <div style="font-size: 1.5rem; margin-bottom: 20px">內容</div>
  <el-tabs
    v-model="activeIndex"
    type="card"
    editable
    class="product-tabs"
    @tab-add="handleAddTabs"
    @tab-remove="handleRemoveTabs"
    :before-leave="validDateProduct"
  >
    <el-tab-pane
      v-for="(item, index) in ruleForm"
      :key="item.index"
      :label="item.product_name || `商品${index + 1}`"
      :name="item.index"
    >
      <el-form ref="ruleFormRef2" :model="item" status-icon hide-required-asterisk label-position="top" :rules="rules">
        <div class="product-form">
          <el-form-item class="form-item" prop="product_id">
            <template #label>
              <span class="label">商品</span>
            </template>
            <el-cascader
              v-model="item.product_id"
              :options="getProductTree"
              :show-all-levels="false"
              :props="productTreeProps"
              placeholder="請選擇或輸入品項名稱"
              clearable
              filterable
              style="width: 100%"
              @change="handleSelectProduct($event, index, '1')"
            />
          </el-form-item>
          <el-form-item class="form-item">
            <template #label>
              <span class="label">容量</span>
            </template>
            <el-select v-model="item.size" placeholder="請選擇容量" style="width: 100%">
              <el-option label="不指定" value="" />
              <el-option
                v-for="option in item.options?.size"
                :key="option"
                :label="mapOptionsLabel(option)"
                :value="option"
              />
            </el-select>
          </el-form-item>
          <el-form-item class="form-item" prop="amount">
            <template #label>
              <span class="label">數量</span>
            </template>
            <el-input v-model="item.amount" placeholder="請輸入數量" type="number" />
          </el-form-item>
        </div>

        <div class="product-form replace">
          <el-form-item class="form-item">
            <template #label>
              <span class="label">可替換商品</span>
            </template>
            <el-cascader
              v-model="selectProduct"
              :options="getProductTree"
              :show-all-levels="false"
              :props="productTreeProps"
              placeholder="請選擇或輸入品項名稱"
              clearable
              filterable
              style="width: 100%"
              @change="handleSelectProduct($event, index, '2')"
              :disabled="!item.product_id"
            />
          </el-form-item>
          <el-form-item class="form-item">
            <template #label>
              <span class="label">容量</span>
            </template>
            <el-select v-model="selectSize" placeholder="請選擇容量" style="width: 100%" :disabled="!item.product_id">
              <el-option label="不指定" value="" />
              <el-option
                v-for="option in item.replace_options?.size"
                :key="option"
                :label="mapOptionsLabel(option)"
                :value="option"
              />
            </el-select>
          </el-form-item>
        </div>

        <div class="product-list" style="grid-area: 4/1/5/3">
          <el-button style="width: 100%; margin-bottom: 8px" :disabled="!selectProduct" @click="handleAddProduct">
            新增可替換商品
            <el-icon style="margin-left: 5px"><CirclePlus /></el-icon>
          </el-button>
          <el-table
            :data="ruleForm[index].replace_product_list"
            style="width: 100%"
            empty-text="請按「新增可替換商品」加入項目，至少新增一項"
            class="product-table"
            header-cell-class-name="product-table-header"
          >
            <el-table-column prop="product_id" label="可替換商品">
              <template #default="scope">
                <span>{{ mapProductLabel(scope.row.product_id) }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="size" label="容量" width="120">
              <template #default="scope">
                <span>{{ mapOptionsLabel(scope.row.size) || '不指定' }}</span>
              </template>
            </el-table-column>
            <el-table-column width="80">
              <template #default="scope">
                <el-button link type="danger" size="small" @click.prevent="handleDeleteProduct(scope.$index)">
                  刪除
                </el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <!-- <el-form-item class="form-item">
          <template #label>
            <span class="label">甜度</span>
          </template>
          <el-select
            v-model="item.sweetness"
            placeholder="請選擇甜度"
            style="width: 100%"
            :disabled="!item.product_id"
          >
            <el-option label="不指定" value="" />
            <el-option
              v-for="item in item.options?.sweetness"
              :key="item"
              :label="mapOptionsLabel(item)"
              :value="item"
            />
          </el-select>
        </el-form-item>
        <el-form-item class="form-item">
          <template #label>
            <span class="label">溫度</span>
          </template>
          <el-select
            v-model="item.temperature"
            placeholder="請選擇溫度"
            style="width: 100%"
            :disabled="!item.product_id"
          >
            <el-option label="不指定" value="" />
            <el-option
              v-for="item in item.options?.temperature"
              :key="item"
              :label="mapOptionsLabel(item)"
              :value="item"
            />
          </el-select>
        </el-form-item> -->
      </el-form>
    </el-tab-pane>
  </el-tabs>
</template>
<script>
import { ref, reactive, getCurrentInstance, computed, watch } from 'vue';
import { useGetters, useActions } from '@/utils/mapStore';
import { mapOptionsLabel, mapProductLabel } from '@/utils/define';
export default {
  name: 'meal-product',
  props: ['form'],
  emits: ['update:form'],
  setup(props, context) {
    const { proxy } = getCurrentInstance();
    //----------------------------------商品內容-----------------------------------
    const ruleForm = computed({
      get: () => props.form,
      set: (val) => context.emit('update:form', val),
    });
    let tabIndex = ruleForm.value.length;
    const activeIndex = ref(ruleForm.value[0].index);
    const ruleFormRef2 = ref(null);

    //商品清單與選項
    const { getProductTree, getProductList } = useGetters('common', ['getProductTree', 'getProductList']);
    const doGetProductOptions = useActions('meal', ['doGetProductOptions']);
    //商品樹props設定
    const productTreeProps = {
      emitPath: false,
      value: 'id',
      label: 'name',
      children: 'product_list',
    };
    const replaceTreeProps = {
      emitPath: false,
      value: 'id',
      label: 'name',
      children: 'product_list',
      multiple: true,
    };

    //選擇商品
    const handleSelectProduct = async (val, index, type) => {
      if (type === '1') {
        if (val) {
          const p_name = getProductList.value.find((e) => e.id === val).name;
          const options = await doGetProductOptions(val);
          ruleForm.value[index].product_name = p_name;
          ruleForm.value[index].size = '';
          ruleForm.value[index].temperature = '';
          ruleForm.value[index].sweetness = '';
          ruleForm.value[index].options = options;
        } else {
          ruleForm.value[index].product_id = '';
          ruleForm.value[index].product_name = '';
          ruleForm.value[index].size = '';
          ruleForm.value[index].temperature = '';
          ruleForm.value[index].sweetness = '';
          ruleForm.value[index].options = null;
          selectProduct.value = '';
          selectSize.value = '';
        }
      } else if (type === '2') {
        if (val) {
          selectSize.value = '';
          const options = await doGetProductOptions(val);
          ruleForm.value[index].replace_options = options;
        } else {
          selectSize.value = '';
          ruleForm.value[index].replace_options = null;
        }
      }
    };

    //新增商品tab
    const handleAddTabs = async () => {
      try {
        await validDateProduct(undefined, activeIndex.value);
        const newIndex = ++tabIndex;
        ruleForm.value.push({
          index: newIndex,
          product_name: '',
          product_id: '',
          amount: '',
          size: [],
          temperature: [],
          sweetness: [],
          replace_product_list: [],
          options: null,
          replace_options: null,
        });
        activeIndex.value = newIndex;
      } catch (e) {
        e;
      }
    };

    //刪除商品tab
    const handleRemoveTabs = (targetIndex) => {
      if (ruleForm.value.length === 1) {
        proxy.$alert(`無法刪除商品`, 'ERROR', {
          type: 'error',
          confirmButtonText: '確認',
          confirmButtonClass: 'el-button--danger',
        });
        return;
      }
      const p_name = ruleForm.value[targetIndex - 1].product_name;
      if (p_name) {
        proxy
          .$confirm(`確定要刪除－${p_name}？`, '確認刪除', {
            confirmButtonText: '刪除',
            cancelButtonText: '取消',
            type: 'warning',
            confirmButtonClass: 'el-button--danger',
          })
          .then(async () => {
            removeTab(targetIndex);
          });
      } else {
        removeTab(targetIndex);
      }
    };
    const is_remove = ref(false);
    const removeTab = (targetIndex) => {
      let newIndex = 1;
      ruleForm.value.splice(targetIndex - 1, 1);
      ruleForm.value.forEach((e) => {
        e.index = newIndex;
        newIndex++;
      });
      is_remove.value = true;
      if (targetIndex <= activeIndex.value) {
        activeIndex.value = activeIndex.value === 1 ? activeIndex.value : activeIndex.value - 1;
      }
      tabIndex = ruleForm.value.length;
    };

    //切換tab時驗證表單
    const validDateProduct = async (index, oldIndex) => {
      //刪除時不驗證
      if (is_remove.value) {
        is_remove.value = false;
        return Promise.resolve(true);
      }
      try {
        await ruleFormRef2.value[oldIndex - 1].validate();
        return Promise.resolve(true);
      } catch {
        proxy.$alert(`尚未完成商品設定`, 'ERROR', {
          type: 'error',
          confirmButtonText: '確認',
          confirmButtonClass: 'el-button--danger',
        });
        return Promise.reject(false);
      }
    };
    //清除單項錯誤提示
    const clearItemValidate = (form, val) => {
      form[activeIndex.value - 1].clearValidate([val]);
    };
    watch(
      () => ruleForm.value[activeIndex.value - 1].product_id,
      () => {
        if (ruleForm.value[activeIndex.value - 1].product_id) clearItemValidate(ruleFormRef2.value, 'product_id');
      }
    );

    //驗證
    const rules = reactive({
      product_id: [{ required: true, message: '請選擇一個商品', trigger: 'blur' }],
      amount: [{ required: true, message: '請輸入數量', trigger: 'blur' }],
    });

    //檢查表單
    const validateForm = async () => {
      //由於切換tab時會檢查表單輸入，所以送出時只檢查當前tab
      try {
        await ruleFormRef2.value[activeIndex.value - 1].validate();
        return true;
      } catch {
        return false;
      }
    };

    //新增可替代商品
    const selectProduct = ref('');
    const selectSize = ref('');
    const handleAddProduct = async () => {
      if (selectProduct.value) {
        ruleForm.value[activeIndex.value - 1].replace_product_list.push({
          product_id: selectProduct.value,
          size: selectSize.value,
        });
      }
      selectProduct.value = '';
      selectSize.value = '';
      ruleForm.value[activeIndex.value - 1].replace_options = [];
    };

    //從列表中刪除價格模式
    const handleDeleteProduct = (index) => {
      ruleForm.value[activeIndex.value - 1].replace_product_list.splice(index, 1);
    };

    return {
      mapOptionsLabel,
      mapProductLabel,
      ruleForm,
      tabIndex,
      activeIndex,
      ruleFormRef2,
      getProductTree,
      productTreeProps,
      replaceTreeProps,
      handleSelectProduct,
      handleAddTabs,
      handleRemoveTabs,
      validDateProduct,
      rules,
      validateForm,
      handleAddProduct,
      handleDeleteProduct,
      selectProduct,
      selectSize,
    };
  },
};
</script>
<style lang="scss" scoped>
.form-item {
  @include basic-form-item;
}
.product-tabs {
  margin-top: 10px;
}
.product-form {
  margin-top: 10px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: repeat(2, 1fr);
  grid-column-gap: 25px;
  overflow: scroll;
}
.replace {
  grid-template-rows: repeat(1, 1fr);
}
:deep(.el-cascader:hover) {
  position: relative;
  z-index: 2;
}
:deep(.el-tabs__new-tab) {
  color: #fff;
  background-color: $color-primary;
  border: 1px solid $color-primary;
  .is-icon-plus {
    font-size: 18px;
  }
}
.product-list {
  width: 100%;
  margin: 0 auto 30px;
  :deep(.product-table) {
    border: 1px solid $el-border-color-lighter !important;
    border-bottom: none !important;
    .product-table-header {
      font-weight: 500;
      color: $color-text-light;
      background-color: $color-background !important;
    }
  }
}
</style>
